import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKxAqt3jzK5DO6mYe_O5F0edrQ-9Dyj1I",
  authDomain: "news-pens-social.firebaseapp.com",
  projectId: "news-pens-social",
  storageBucket: "news-pens-social.appspot.com",
  messagingSenderId: "757479614391",
  appId: "1:757479614391:web:f315c6c2cddbee8a006a32",
  measurementId: "G-DSMGBCE4S5"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
